/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignToCustomerRequestBody,
  BadRequest,
  Forbidden,
  GatewayResponseModel,
  NotFound,
  UnallocatedDeviceResponseModel,
  UnallocatedGatewayInstallationRequestModel,
  UnallocatedGatewayResponseModel,
  UnallocatedInstallationDeviceUpdateModel,
  UnallocatedInstallationsResponseModel,
  Unauthorized,
} from '../models';

export interface UnallocatedInstallationsAddGatewayDeviceRequest {
    unallocatedGatewayInstallationRequestModel: UnallocatedGatewayInstallationRequestModel;
}

export interface UnallocatedInstallationsAssignToCustomerRequest {
    installationId: string;
    assignToCustomerRequestBody: AssignToCustomerRequestBody;
}

export interface UnallocatedInstallationsDeleteInstallationRequest {
    installationId: string;
}

export interface UnallocatedInstallationsGetUnallocatedInstallationDevicesRequest {
    installation: string;
}

export interface UnallocatedInstallationsGetUnallocatedInstallationGatewaysRequest {
    installation: string;
}

export interface UnallocatedInstallationsGetUnallocatedInstallationsRequest {
    page?: number;
    limit?: number;
    tenantId?: string;
    installerId?: string;
    startCode?: string;
}

export interface UnallocatedInstallationsScanGatewayDevicesRequest {
    installationId: string;
    gatewayId: string;
}

export interface UnallocatedInstallationsUpdateDeviceConfigurationRequest {
    installationId: string;
    deviceId: string;
    unallocatedInstallationDeviceUpdateModel: UnallocatedInstallationDeviceUpdateModel;
}

/**
 * 
 */
export class UnallocatedInstallationsApi extends runtime.BaseAPI {

    /**
     * Add an unallocated gateway configuration
     */
    async unallocatedInstallationsAddGatewayDeviceRaw(requestParameters: UnallocatedInstallationsAddGatewayDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnallocatedGatewayResponseModel>> {
        if (requestParameters.unallocatedGatewayInstallationRequestModel === null || requestParameters.unallocatedGatewayInstallationRequestModel === undefined) {
            throw new runtime.RequiredError('unallocatedGatewayInstallationRequestModel','Required parameter requestParameters.unallocatedGatewayInstallationRequestModel was null or undefined when calling unallocatedInstallationsAddGatewayDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/unallocated-installations/gateway`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.unallocatedGatewayInstallationRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Add an unallocated gateway configuration
     */
    async unallocatedInstallationsAddGatewayDevice(requestParameters: UnallocatedInstallationsAddGatewayDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnallocatedGatewayResponseModel> {
        const response = await this.unallocatedInstallationsAddGatewayDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Assign unallocated installation to a customer by id or email
     */
    async unallocatedInstallationsAssignToCustomerRaw(requestParameters: UnallocatedInstallationsAssignToCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayResponseModel>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling unallocatedInstallationsAssignToCustomer.');
        }

        if (requestParameters.assignToCustomerRequestBody === null || requestParameters.assignToCustomerRequestBody === undefined) {
            throw new runtime.RequiredError('assignToCustomerRequestBody','Required parameter requestParameters.assignToCustomerRequestBody was null or undefined when calling unallocatedInstallationsAssignToCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/unallocated-installations/{installationId}/assignCustomer`.replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.assignToCustomerRequestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Assign unallocated installation to a customer by id or email
     */
    async unallocatedInstallationsAssignToCustomer(requestParameters: UnallocatedInstallationsAssignToCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayResponseModel> {
        const response = await this.unallocatedInstallationsAssignToCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove an unallocated installation
     */
    async unallocatedInstallationsDeleteInstallationRaw(requestParameters: UnallocatedInstallationsDeleteInstallationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling unallocatedInstallationsDeleteInstallation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/unallocated-installations/{installationId}`.replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove an unallocated installation
     */
    async unallocatedInstallationsDeleteInstallation(requestParameters: UnallocatedInstallationsDeleteInstallationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unallocatedInstallationsDeleteInstallationRaw(requestParameters, initOverrides);
    }

    /**
     * Get unallocated installation devices
     */
    async unallocatedInstallationsGetUnallocatedInstallationDevicesRaw(requestParameters: UnallocatedInstallationsGetUnallocatedInstallationDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UnallocatedDeviceResponseModel>>> {
        if (requestParameters.installation === null || requestParameters.installation === undefined) {
            throw new runtime.RequiredError('installation','Required parameter requestParameters.installation was null or undefined when calling unallocatedInstallationsGetUnallocatedInstallationDevices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/unallocated-installations/{installation}/devices`.replace(`{${"installation"}}`, encodeURIComponent(String(requestParameters.installation))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get unallocated installation devices
     */
    async unallocatedInstallationsGetUnallocatedInstallationDevices(requestParameters: UnallocatedInstallationsGetUnallocatedInstallationDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UnallocatedDeviceResponseModel>> {
        const response = await this.unallocatedInstallationsGetUnallocatedInstallationDevicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an unallocated installation gateways information
     */
    async unallocatedInstallationsGetUnallocatedInstallationGatewaysRaw(requestParameters: UnallocatedInstallationsGetUnallocatedInstallationGatewaysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UnallocatedGatewayResponseModel>>> {
        if (requestParameters.installation === null || requestParameters.installation === undefined) {
            throw new runtime.RequiredError('installation','Required parameter requestParameters.installation was null or undefined when calling unallocatedInstallationsGetUnallocatedInstallationGateways.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/unallocated-installations/{installation}/gateways`.replace(`{${"installation"}}`, encodeURIComponent(String(requestParameters.installation))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get an unallocated installation gateways information
     */
    async unallocatedInstallationsGetUnallocatedInstallationGateways(requestParameters: UnallocatedInstallationsGetUnallocatedInstallationGatewaysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UnallocatedGatewayResponseModel>> {
        const response = await this.unallocatedInstallationsGetUnallocatedInstallationGatewaysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of unallocated installations with filter options
     */
    async unallocatedInstallationsGetUnallocatedInstallationsRaw(requestParameters: UnallocatedInstallationsGetUnallocatedInstallationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnallocatedInstallationsResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenantId'] = requestParameters.tenantId;
        }

        if (requestParameters.installerId !== undefined) {
            queryParameters['installerId'] = requestParameters.installerId;
        }

        if (requestParameters.startCode !== undefined) {
            queryParameters['startCode'] = requestParameters.startCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/unallocated-installations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get a list of unallocated installations with filter options
     */
    async unallocatedInstallationsGetUnallocatedInstallations(requestParameters: UnallocatedInstallationsGetUnallocatedInstallationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnallocatedInstallationsResponseModel> {
        const response = await this.unallocatedInstallationsGetUnallocatedInstallationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Installation scan appliances
     */
    async unallocatedInstallationsScanGatewayDevicesRaw(requestParameters: UnallocatedInstallationsScanGatewayDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling unallocatedInstallationsScanGatewayDevices.');
        }

        if (requestParameters.gatewayId === null || requestParameters.gatewayId === undefined) {
            throw new runtime.RequiredError('gatewayId','Required parameter requestParameters.gatewayId was null or undefined when calling unallocatedInstallationsScanGatewayDevices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/unallocated-installations/{installationId}/gateway/{gatewayId}/scan`.replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))).replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters.gatewayId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Installation scan appliances
     */
    async unallocatedInstallationsScanGatewayDevices(requestParameters: UnallocatedInstallationsScanGatewayDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unallocatedInstallationsScanGatewayDevicesRaw(requestParameters, initOverrides);
    }

    /**
     * Update unallocated installation device configuration
     */
    async unallocatedInstallationsUpdateDeviceConfigurationRaw(requestParameters: UnallocatedInstallationsUpdateDeviceConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnallocatedDeviceResponseModel>> {
        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling unallocatedInstallationsUpdateDeviceConfiguration.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling unallocatedInstallationsUpdateDeviceConfiguration.');
        }

        if (requestParameters.unallocatedInstallationDeviceUpdateModel === null || requestParameters.unallocatedInstallationDeviceUpdateModel === undefined) {
            throw new runtime.RequiredError('unallocatedInstallationDeviceUpdateModel','Required parameter requestParameters.unallocatedInstallationDeviceUpdateModel was null or undefined when calling unallocatedInstallationsUpdateDeviceConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/unallocated-installations/{installationId}/devices/{deviceId}`.replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.unallocatedInstallationDeviceUpdateModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update unallocated installation device configuration
     */
    async unallocatedInstallationsUpdateDeviceConfiguration(requestParameters: UnallocatedInstallationsUpdateDeviceConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnallocatedDeviceResponseModel> {
        const response = await this.unallocatedInstallationsUpdateDeviceConfigurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
